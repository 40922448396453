body,
html {
  margin: 0;
  height: 100%;
}

/* GrapesJS temporary fix #2490 */
.gjs-clm-tag-status,
.gjs-clm-tag-close {
}
.gjs-clm-tags-btn {
  width: 24px;
}

/* Fix hidden scroll */
.gjs-pn-views-container {
  height: auto;
  padding: 0 0 0;
  top: 40px;
  bottom: 0;
  background: #f0f0f0;
}

.gjs-btn-code-edit {
  margin-top: 10px;
}

/* GrapesJS FileManager custom CSS */
.gjs-btn-code-cancel {
  margin-left: 10px;
}

.gjs-am-file-uploader {
  width: 100%;
  float: none;
}

.gjs-am-assets-cont {
  width: 100%;
  float: none;
}

.gjs-am-assets {
  height: 280px;
}

.gjs-am-asset {
  width: 25%;
}

.gjs-am-file-uploader > form #gjs-am-uploadFile {
  padding: 20px 10px;
}

.gjs-am-file-uploader #gjs-am-title {
  padding: 20px 10px;
}

.gjs-am-preview {
  background-size: contain;
}

.gjs-am-preview-cont {
  width: 40%;
}

.gjs-am-meta {
  width: 60%;
}

.gjs-pn-commands .gjs-pn-buttons {
  justify-content: center;
}

/* GrapesJS Colors / Theme */
.gjs-clm-tags .gjs-sm-title,
.gjs-sm-sector .gjs-sm-title {
}

.gjs-clm-tags .gjs-clm-tag {
  border: 1px solid #707070;
  box-shadow: none;
  text-shadow: none;
}

.gjs-field {
  box-shadow: none;
}

.gjs-btnt.gjs-pn-active,
.gjs-pn-btn.gjs-pn-active {
  box-shadow: none;
}

.gjs-import-label,
.gjs-export-label {
  margin-bottom: 10px;
  font-size: 13px;
}

.gjs-mdl-dialog .gjs-btn-import {
  margin-top: 10px;
}

.CodeMirror {
  border-radius: 3px;
  height: 450px;
  font-family: sans-serif, monospace;
  letter-spacing: 0.3px;
  font-size: 12px;
}

/* GrapesJS Extra */
#gjs-pn-views-container.gjs-pn-panel {
  padding: 39px 0 0;
}

#gjs-pn-views.gjs-pn-panel {
  padding: 0;
  border: none;
}

#gjs-pn-views .gjs-pn-btn {
  margin: 0;
  height: 40px;
  padding: 10px;
  width: 25%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}

#gjs-pn-views .gjs-pn-active {
  border-radius: 0;
}

#gjs-pn-devices-c {
  padding-left: 30px;
}

#gjs-pn-options {
  padding-right: 30px;
}

.gjs-sm-composite .gjs-sm-properties {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

#gjs-sm-border-top-left-radius,
#gjs-sm-border-top-right-radius,
#gjs-sm-border-bottom-left-radius,
#gjs-sm-border-bottom-right-radius,
#gjs-sm-margin-top,
#gjs-sm-margin-bottom,
#gjs-sm-margin-right,
#gjs-sm-margin-left,
#gjs-sm-padding-top,
#gjs-sm-padding-bottom,
#gjs-sm-padding-right,
#gjs-sm-padding-left {
}

#gjs-sm-border-width,
#gjs-sm-border-style,
#gjs-sm-border-color {
  flex: 999 1 80px;
}

#gjs-sm-margin-left,
#gjs-sm-padding-left {
  order: 2;
}

#gjs-sm-margin-right,
#gjs-sm-padding-right {
  order: 3;
}

#gjs-sm-margin-bottom,
#gjs-sm-padding-bottom {
  order: 4;
}

.gjs-field-radio {
  width: 100%;
}

.gjs-field-radio #gjs-sm-input-holder {
  display: flex;
}

.gjs-radio-item {
  flex: 1 0 auto;
  text-align: center;
}

.gjs-sm-sector .gjs-sm-property.gjs-sm-list {
  width: 50%;
}

.gjs-mdl-content {
  border-top: none;
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-layer.gjs-sm-active {
  background-color: rgba(255, 255, 255, 0.09);
}

.gjs-f-button::before {
  content: 'B';
}

.gjs-f-divider::before {
  content: 'D';
}

.gjs-mdl-dialog-sm {
  width: 300px;
}

.gjs-mdl-dialog form .gjs-sm-property {
  font-size: 12px;
  margin-bottom: 15px;
}

.gjs-mdl-dialog form .gjs-sm-label {
  margin-bottom: 5px;
}

#gjs-clm-status-c {
  display: none;
}

.anim-spin {
  animation: 0.5s linear 0s normal none infinite running spin;
}

.form-status {
  float: right;
  font-size: 14px;
}

.text-danger {
  color: #f92929;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.is-not-allowed {
  cursor: not-allowed;
}

.link-is-disabled {
  pointer-events: none;
  text-decoration: none;
  display: inline-block;
  opacity: 0.5;
}
